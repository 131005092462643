import { Review } from "common/types/review";

export const googleReviews: Review[] = [
  {
    review_id: "ALV-UjVwOtDZGOwF302RjjyzUlP3LeWXd4nmswCgFMeMCroFLmVCVrRD",
    type: "google",
    image_link:
      "https://lh3.googleusercontent.com/a-/ALV-UjVwOtDZGOwF302RjjyzUlP3LeWXd4nmswCgFMeMCroFLmVCVrRD=w63-h63-p-rp-mo-ba4",
    customer_name: "Pugs一言で言えば",
    created_time: "a week ago",
    link: "https://goo.gl/maps/Gixp8649japUZREf8",
    review_text:
      "Excellent service! special shoutout to Marshall for assisting me on the delivery and fulfilling my requests on  specific configurations that I want on my PC! Kudos to all the staff @ PC Worth! No better deals than this",
  },
  {
    review_id: "ALV-UjV5FVVf0hxDg9ntcxCDSBvUtQ2vczkfg3WcUbXC7d-SQaF2-hc",
    type: "google",
    image_link:
      "https://lh3.googleusercontent.com/a-/ALV-UjV5FVVf0hxDg9ntcxCDSBvUtQ2vczkfg3WcUbXC7d-SQaF2-hc=w63-h63-p-rp-mo",
    customer_name: "KukkiScr",
    created_time: "3 weeks ago",
    link: "https://goo.gl/maps/Gixp8649japUZREf8",
    review_text:
      "Highly recommend this place for pc enthusiasts and for those who want their first pc built by professionals. They have a great website, very easy to navigate and it also includes a pc builder for compiling all the parts you want to buy. …",
  },
  {
    review_id: "g8ocJY_4tRyYRjHraTO_FFm5F40q1b8mOh2K8vX1HOzQWfRsyj",
    type: "google",
    image_link:
      "https://lh3.googleusercontent.com/a/ACg8ocJY_4tRyYRjHraTO_FFm5F40q1b8mOh2K8vX1HOzQWfRsyj=w63-h63-p-rp-mo",
    customer_name: "Lester Nitural",
    created_time: "2 years ago",
    link: "https://goo.gl/maps/Gixp8649japUZREf8",
    review_text:
      "Staff was friendly and knowledgeable about the products. Low pricing, recommended to those who want to build their rigs.",
  },
  {
    review_id: "ALV-UjXaYAI7bHR6LbU4k6Xo3whcXbz60hy9OU-L2tit9AQ5_Ogywnk",
    type: "google",
    image_link:
      "https://lh3.googleusercontent.com/a-/ALV-UjXaYAI7bHR6LbU4k6Xo3whcXbz60hy9OU-L2tit9AQ5_Ogywnk=w63-h63-p-rp-mo",
    customer_name: "mark louie gallardo",
    created_time: "4 weeks ago",
    link: "https://goo.gl/maps/Gixp8649japUZREf8",
    review_text:
      "Solid customer service, kahit gabi may sumasagot sa fb nila, free delivery din at cod, might buy again from them for my pc build.",
  },
  {
    review_id: "ALV-UjX_tpjNBieQohduf6AmS0iACkFf4dV3fvDVihNALu9XkzgVID7Ftw",
    type: "google",
    image_link:
      "https://lh3.googleusercontent.com/a-/ALV-UjX_tpjNBieQohduf6AmS0iACkFf4dV3fvDVihNALu9XkzgVID7Ftw=w63-h63-p-rp-mo",
    customer_name: "Johann Paolo Tanhueco",
    created_time: "a month ago",
    link: "https://goo.gl/maps/Gixp8649japUZREf8",
    review_text:
      "Definitely recommending PC Worth. Their FB chat mod answers patiently and promptly to inquiries. Their website has a PC builder complete with details and prices (best/lowest). They are really smooth to work with, I came in their store as a walk in and got home with a pc built the same day. After sales is top notch too!",
  },
  {
    review_id: "ALV-UjUkbcaDq7Xwud6AtOkCvG_k0dN9tmQjG5wDyfjRC8hBQd4jEveI",
    type: "google",
    image_link:
      "https://lh3.googleusercontent.com/a-/ALV-UjUkbcaDq7Xwud6AtOkCvG_k0dN9tmQjG5wDyfjRC8hBQd4jEveI=w63-h63-p-rp-mo",
    customer_name: "aceraffy francisco",
    created_time: "9 months ago",
    link: "https://goo.gl/maps/Gixp8649japUZREf8",
    review_text:
      "Solid and very cheap for a mid gaming system unit! And fyi you can DIY build on their website. Thanks to sir kenneth for assisting although there is a delay in delivering it is still worth it! Kudos! 🫡 …",
  },
  {
    review_id: "g8ocICY7F-oaJnULGl1FHXYa0M_R2B30xBsQMegOXlJUHHzA3Cvw",
    type: "google",
    image_link:
      "https://lh3.googleusercontent.com/a/ACg8ocICY7F-oaJnULGl1FHXYa0M_R2B30xBsQMegOXlJUHHzA3Cvw=w63-h63-p-rp-mo",
    customer_name: "Bobert McMillanson",
    created_time: "a year ago",
    link: "https://goo.gl/maps/Gixp8649japUZREf8",
    review_text:
      "The least stressful and most accommodating PC shop I've encountered. Their website was easy to use (which cannot be said about every other store), and their physical store is as sleek as the website. Staff were very helpful and friendly.",
  },
  {
    review_id: "ALV-UjXULOi2P-N4CcyvEx4SZBQvkuqUFY0lfHjMOiz2nOWKbRewh-Yrow",
    type: "google",
    image_link:
      "https://lh3.googleusercontent.com/a-/ALV-UjXULOi2P-N4CcyvEx4SZBQvkuqUFY0lfHjMOiz2nOWKbRewh-Yrow=w63-h63-p-rp-mo-ba2",
    customer_name: "Adrian Louise Galvez",
    created_time: "a year ago",
    link: "https://goo.gl/maps/Gixp8649japUZREf8",
    review_text:
      "Kind of hard to find but the service and price is top notch\n\nEdit: Their store is nice, clean, and easy to get to. So from 4 stars, I'll rate this 5 stars! Good job PC Worth!",
  },
  {
    review_id: "ALV-UjW2oHeWZhWHYiJJ4a3-pkpyagNKRlZ96HPFNJyP7FIQcJOnLEoC",
    type: "google",
    image_link:
      "https://lh3.googleusercontent.com/a-/ALV-UjW2oHeWZhWHYiJJ4a3-pkpyagNKRlZ96HPFNJyP7FIQcJOnLEoC=w63-h63-p-rp-mo",
    customer_name: "Kiko",
    created_time: "a year ago",
    link: "https://goo.gl/maps/Gixp8649japUZREf8",
    review_text:
      "Customer service was top notch as if you were at home while waiting for your pc to be built hahaha and the staff is nice you can ask them your concerns or if there are things you don't get thank you so much pcworth!!",
  },
  {
    review_id: "ALV-UjWZ-xbIfeNK07_O9qYa-d4ybRapphA7FudbLdQJPJ0Qz9kTvLDh",
    type: "google",
    image_link:
      "https://lh3.googleusercontent.com/a-/ALV-UjWZ-xbIfeNK07_O9qYa-d4ybRapphA7FudbLdQJPJ0Qz9kTvLDh=w63-h63-p-rp-mo",
    customer_name: "Jobin Abrenica",
    created_time: "a year ago",
    link: "https://goo.gl/maps/Gixp8649japUZREf8",
    review_text:
      "The staff was very accommodating, especially Sir Edwin, Sir Vince and the old man in the white t-shirt (sorry I can't remember the name) whom I even helped put the case and unit in the car. The cable management is also very clean and the …",
  },
  {
    review_id: "ALV-UjUkSK-zmZIvIqac7Us3uTrddj3RtkoYr_JZcCPnMRmDeoucQiqB",
    type: "google",
    image_link:
      "https://lh3.googleusercontent.com/a-/ALV-UjUkSK-zmZIvIqac7Us3uTrddj3RtkoYr_JZcCPnMRmDeoucQiqB=w63-h63-p-rp-mo-ba3",
    customer_name: "Johnnyjohnnyjohnny",
    created_time: "a year ago",
    link: "https://goo.gl/maps/Gixp8649japUZREf8",
    review_text:
      "A good store to buy PC Parts, accommodating Staffs, clean and well air conditioned. I will come back to buy a new GPU in the future.",
  },
  {
    review_id: "ALV-UjX0r7sFEb22qN-qmmEeE0_0LAf36kVlfUDwCtneusVT-nBK3QJD",
    type: "google",
    image_link:
      "https://lh3.googleusercontent.com/a-/ALV-UjX0r7sFEb22qN-qmmEeE0_0LAf36kVlfUDwCtneusVT-nBK3QJD=w63-h63-p-rp-mo",
    customer_name: "Yui 惟",
    created_time: "2 years ago",
    link: "https://goo.gl/maps/Gixp8649japUZREf8",
    review_text:
      "So, I was finding a replacement board and CPU for the old build (My FSP Raider RA650 80+ Silver that ran for 2 years bit the dust, and I had to sell my 7th gen parts except for my graphics card and memory) and I was looking for good deals …",
  },
  {
    review_id: "ALV-UjWb-EaZON6FEyeGFeeFqVuHzHk3_ZZ8QOV5tRBcR7F83Pz23KyX",
    type: "google",
    image_link:
      "https://lh3.googleusercontent.com/a-/ALV-UjWb-EaZON6FEyeGFeeFqVuHzHk3_ZZ8QOV5tRBcR7F83Pz23KyX=w63-h63-p-rp-mo",
    customer_name: "Ronn Gonzales",
    created_time: "3 years ago",
    link: "https://goo.gl/maps/Gixp8649japUZREf8",
    review_text:
      "All staffs are good to talk with 😊 The best one stop shop that will bang your buck! Excellent Cable Management! Kudos to PC WORTH. Worth it na worth it ang babayad mo 😊😁 …",
  },
  {
    review_id: "ALV-UjWjm6b22LzvBzVRlIZ6s-9-SGtlRtiLMh8iSCyVJZbwV697kdnw",
    type: "google",
    image_link:
      "https://lh3.googleusercontent.com/a-/ALV-UjWjm6b22LzvBzVRlIZ6s-9-SGtlRtiLMh8iSCyVJZbwV697kdnw=w63-h63-p-rp-mo",
    customer_name: "Mary Jho Reyes",
    created_time: "3 years ago",
    link: "https://goo.gl/maps/Gixp8649japUZREf8",
    review_text:
      "Ayos kausap ang mga tao dito. Nag bibigay sila ng payo regarding sa pc mo. Good services and product kaso tago nga lang sila sabagay online store lang sila Parang serect shop lang sa dota 😀",
  },
  {
    review_id: "ALV-UjVraTlYD1R8UsBaS5fx5t2Aw-mwtrVt5neeDroJj734c4QxZyJ-",
    type: "google",
    image_link:
      "https://lh3.googleusercontent.com/a-/ALV-UjVraTlYD1R8UsBaS5fx5t2Aw-mwtrVt5neeDroJj734c4QxZyJ-=w63-h63-p-rp-mo-ba2",
    customer_name: "maricris francia",
    created_time: "2 years ago",
    link: "https://goo.gl/maps/Gixp8649japUZREf8",
    review_text:
      "Ok mga staff ng PC Worth, accomodating and trusted sila. Worth it pumunta at mag purchase dito. Wala silang parking space, kasi parang residence area yung place nila. Makikipark ka lang sa isang grocery store dun malapit sa area, and then mag walk through ka lang papunta sa shop nila.",
  },
  {
    review_id: "ALV-UjXj6bdBL7XxaeZ7Gp9o-dlXKuVGJaVb8l-fNMaAqO4Ltuwuaqk",
    type: "google",
    image_link:
      "https://lh3.googleusercontent.com/a-/ALV-UjXj6bdBL7XxaeZ7Gp9o-dlXKuVGJaVb8l-fNMaAqO4Ltuwuaqk=w63-h63-p-rp-mo-ba4",
    customer_name: "Leen Jay Monreal",
    created_time: "a week ago",
    link: "https://goo.gl/maps/Gixp8649japUZREf8",
    review_text: "It was busy, but shop is clean and attendants are nice",
  },
  {
    review_id: "ALV-UjUt9EIubMconMo9EbHTVR-1e_ZqAwb9zjpSPj1kBeTA3pk8W_2d",
    type: "google",
    image_link:
      "https://lh3.googleusercontent.com/a-/ALV-UjUt9EIubMconMo9EbHTVR-1e_ZqAwb9zjpSPj1kBeTA3pk8W_2d=w63-h63-p-rp-mo",
    customer_name: "Kevin Bautista",
    created_time: "3 months ago",
    link: "https://goo.gl/maps/Gixp8649japUZREf8",
    review_text:
      "Cheapest market price you can find. The service is also fast.",
  },
  {
    review_id: "ALV-UjWDza052n6i9MANin2YzrMcvJUAezNwpVAY4b0tg0KWaqwvJhk",
    type: "google",
    image_link:
      "https://lh3.googleusercontent.com/a-/ALV-UjWDza052n6i9MANin2YzrMcvJUAezNwpVAY4b0tg0KWaqwvJhk=w63-h63-p-rp-mo-ba3",
    customer_name: "Shaneen Aboy",
    created_time: "4 months ago",
    link: "https://goo.gl/maps/Gixp8649japUZREf8",
    review_text: "Fast and Easy transaction. Thank you for assisting, Kenneth.",
  },
  {
    review_id: "ALV-UjVEn_V9Kf1vgJ7ACXgd8vBVGXdXJSJpqTIGXGSgsJCG7rFrMJoq",
    type: "google",
    image_link:
      "https://lh3.googleusercontent.com/a-/ALV-UjVEn_V9Kf1vgJ7ACXgd8vBVGXdXJSJpqTIGXGSgsJCG7rFrMJoq=w63-h63-p-rp-mo",
    customer_name: "John Paul Domingo",
    created_time: "5 months ago",
    link: "https://goo.gl/maps/Gixp8649japUZREf8",
    review_text:
      "BEST PC SHOP EVER!!, VERY HELPFUL STAFF ESPECIALLY BROTHER VINCENT",
  },
  {
    review_id: "ALV-UjW-B1GuslbNx-3BfyZ9Qfe54TepSst6ulKDS-if6W_1YO3tzng",
    type: "google",
    image_link:
      "https://lh3.googleusercontent.com/a-/ALV-UjW-B1GuslbNx-3BfyZ9Qfe54TepSst6ulKDS-if6W_1YO3tzng=w63-h63-p-rp-mo",
    customer_name: "Silerio, Reuben",
    created_time: "5 months ago",
    link: "https://goo.gl/maps/Gixp8649japUZREf8",
    review_text: "Best PC shop ever, mura pa",
  },
  {
    review_id: "ALV-UjWkzszk_1Xs_dNAFt3LSPO0XDrb0yCL5vlCReKVhndXG90iTSGi",
    type: "google",
    image_link:
      "https://lh3.googleusercontent.com/a-/ALV-UjWkzszk_1Xs_dNAFt3LSPO0XDrb0yCL5vlCReKVhndXG90iTSGi=w63-h63-p-rp-mo",
    customer_name: "Renniel Leslie Kuan",
    created_time: "6 months ago",
    link: "https://goo.gl/maps/Gixp8649japUZREf8",
    review_text: "1000/100 stars!!! Customer Service alone is worth it",
  },
  {
    review_id: "g8ocK_JW9TtXk3DXOLeohkQmRD8TM8pIGR9bGvk-p_Rmh3SX6R7OY",
    type: "google",
    image_link:
      "https://lh3.googleusercontent.com/a/ACg8ocK_JW9TtXk3DXOLeohkQmRD8TM8pIGR9bGvk-p_Rmh3SX6R7OY=w63-h63-p-rp-mo",
    customer_name: "Arjay Serminio",
    created_time: "9 months ago",
    link: "https://goo.gl/maps/Gixp8649japUZREf8",
    review_text:
      "The transaction was very smooth and smooth, thanks to boss kenneth for assisting!😎 …",
  },
  {
    review_id: "g8ocJZU9_ua9LNRvWag-3HMHnwiKwJjV-86YHgQsE052zE_M_daw",
    type: "google",
    image_link:
      "https://lh3.googleusercontent.com/a/ACg8ocJZU9_ua9LNRvWag-3HMHnwiKwJjV-86YHgQsE052zE_M_daw=w63-h63-p-rp-mo",
    customer_name: "Jerome Divino",
    created_time: "10 months ago",
    link: "https://goo.gl/maps/Gixp8649japUZREf8",
    review_text:
      "Highly recommended, the staff are very easy to talk to and very approachable....",
  },
  {
    review_id: "ALV-UjUktWk3dHR4Opkgpmz3GHPGMAGSsCtUlg2Dyemsr0y2543Lagl0FA",
    type: "google",
    image_link:
      "https://lh3.googleusercontent.com/a-/ALV-UjUktWk3dHR4Opkgpmz3GHPGMAGSsCtUlg2Dyemsr0y2543Lagl0FA=w63-h63-p-rp-mo-ba3",
    customer_name: "Elyzon Jade Reaño",
    created_time: "11 months ago",
    link: "https://goo.gl/maps/Gixp8649japUZREf8",
    review_text: "Amazing customer service.",
  },
  {
    review_id: "ALV-UjXp9gWnXzHaOGo1rvYPipro73AzrTxb-IPl92Fjgj4xvYVDccUa",
    type: "google",
    image_link:
      "https://lh3.googleusercontent.com/a-/ALV-UjXp9gWnXzHaOGo1rvYPipro73AzrTxb-IPl92Fjgj4xvYVDccUa=w63-h63-p-rp-mo",
    customer_name: "Edron Magistrado",
    created_time: "a year ago",
    link: "https://goo.gl/maps/Gixp8649japUZREf8",
    review_text: "Cool peeps!!",
  },
  {
    review_id: "ALV-UjV1sGsSS1P2RQnB55PwptoGSVd_et2Twi-Hh2vk5Sl5EC3FTHzX",
    type: "google",
    image_link:
      "https://lh3.googleusercontent.com/a-/ALV-UjV1sGsSS1P2RQnB55PwptoGSVd_et2Twi-Hh2vk5Sl5EC3FTHzX=w63-h63-p-rp-mo",
    customer_name: "Jiro",
    created_time: "a year ago",
    link: "https://goo.gl/maps/Gixp8649japUZREf8",
    review_text: "I hope it's good if there is no brief included😞 …",
  },
  {
    review_id: "ALV-UjVvIM9vYA1Ryoap2qNysDxt-_7cDxydJFSuXuVG_ZUDLrBBu5A6Ww",
    type: "google",
    image_link:
      "https://lh3.googleusercontent.com/a-/ALV-UjVvIM9vYA1Ryoap2qNysDxt-_7cDxydJFSuXuVG_ZUDLrBBu5A6Ww=w63-h63-p-rp-mo-ba5",
    customer_name: "Kim Hansen",
    created_time: "a year ago",
    link: "https://goo.gl/maps/Gixp8649japUZREf8",
    review_text:
      "Amazing prices and builder online. Ready next day wow. And amazing support and service.",
  },
  {
    review_id: "ALV-UjVseaY8tfzWKOMVm5TN88gnJWJ7jMtzvU7q4iJ5330tjzoR_Ac",
    type: "google",
    image_link:
      "https://lh3.googleusercontent.com/a-/ALV-UjVseaY8tfzWKOMVm5TN88gnJWJ7jMtzvU7q4iJ5330tjzoR_Ac=w63-h63-p-rp-mo-ba2",
    customer_name: "Aaron Alcantara",
    created_time: "a year ago",
    link: "https://goo.gl/maps/Gixp8649japUZREf8",
    review_text: "Most accomodating computer store",
  },
  {
    review_id: "g8ocLZsVEJkqIwXoQ7V1heQuIzjokeRGFBywHyqXZHbneirDD2HQ",
    type: "google",
    image_link:
      "https://lh3.googleusercontent.com/a/ACg8ocLZsVEJkqIwXoQ7V1heQuIzjokeRGFBywHyqXZHbneirDD2HQ=w63-h63-p-rp-mo-ba4",
    customer_name: "Jun Umali",
    created_time: "a year ago",
    link: "https://goo.gl/maps/Gixp8649japUZREf8",
    review_text: "Prices are reasonable, but parking is abismal",
  },
  {
    review_id: "ALV-UjWQxq8vGRL47hs1t3G_COxzo4QuKJ5eoo8I31AiFdqRhlLWnMFJ",
    type: "google",
    image_link:
      "https://lh3.googleusercontent.com/a-/ALV-UjWQxq8vGRL47hs1t3G_COxzo4QuKJ5eoo8I31AiFdqRhlLWnMFJ=w63-h63-p-rp-mo-ba4",
    customer_name: "huntGREED",
    created_time: "a year ago",
    link: "https://goo.gl/maps/Gixp8649japUZREf8",
    review_text:
      "Bang for the buck pc builds. Staffs are accommodating. Very flexible payment options.",
  },
  {
    review_id: "g8ocLGxTBOdNldVtgM0M69Z_WAlWtXWjQaaegviJAKoyCEjUCUzbI",
    type: "google",
    image_link:
      "https://lh3.googleusercontent.com/a/ACg8ocLGxTBOdNldVtgM0M69Z_WAlWtXWjQaaegviJAKoyCEjUCUzbI=w63-h63-p-rp-mo-ba3",
    customer_name: "Marvin Jay Bequillo",
    created_time: "5 months ago",
    link: "https://goo.gl/maps/Gixp8649japUZREf8",
    review_text: "HAVE CATS",
  },
  {
    review_id: "g8ocKDM-F_3mDA2zlomS_gdZnBuq3ThEhQOHxW7qJdB-_tZB_WIQ",
    type: "google",
    image_link:
      "https://lh3.googleusercontent.com/a/ACg8ocKDM-F_3mDA2zlomS_gdZnBuq3ThEhQOHxW7qJdB-_tZB_WIQ=w63-h63-p-rp-mo",
    customer_name: "RL Martinez",
    created_time: "a year ago",
    link: "https://goo.gl/maps/Gixp8649japUZREf8",
    review_text: "Solid! smooth transactions, and Extra mile service!",
  },
  {
    review_id: "ALV-UjXmVs2Nv5RcK7xGd_eX-rXK0v-j66lKJFji5XDPYjs52gx5jiA",
    type: "google",
    image_link:
      "https://lh3.googleusercontent.com/a-/ALV-UjXmVs2Nv5RcK7xGd_eX-rXK0v-j66lKJFji5XDPYjs52gx5jiA=w63-h63-p-rp-mo",
    customer_name: "Fritz Mark Ningas",
    created_time: "2 years ago",
    link: "https://goo.gl/maps/Gixp8649japUZREf8",
    review_text:
      "Really different pc worth 10/10 service!\nGreat customer support!!\nTill next upgrade!!",
  },
  {
    review_id: "ALV-UjUllswJHQ1qAwxAJs933yedzBvi-8szJqUhEGjNP-XJCniR3U9x",
    type: "google",
    image_link:
      "https://lh3.googleusercontent.com/a-/ALV-UjUllswJHQ1qAwxAJs933yedzBvi-8szJqUhEGjNP-XJCniR3U9x=w63-h63-p-rp-mo-ba4",
    customer_name: "Lea Estiller",
    created_time: "2 years ago",
    link: "https://goo.gl/maps/Gixp8649japUZREf8",
    review_text: "Very accomodating and offers a very competitive price.",
  },
  {
    review_id: "ALV-UjWryuDWBOmiMPOGkDzOzoUigtABalGS-Geh2UwafLW9DnhbH_qR",
    type: "google",
    image_link:
      "https://lh3.googleusercontent.com/a-/ALV-UjWryuDWBOmiMPOGkDzOzoUigtABalGS-Geh2UwafLW9DnhbH_qR=w63-h63-p-rp-mo",
    customer_name: "Sergio Sanchez",
    created_time: "2 years ago",
    link: "https://goo.gl/maps/Gixp8649japUZREf8",
    review_text: "Very helpful staffs",
  },
  {
    review_id: "ALV-UjWOh24z1nEhGpLqqdbrHkGOMnV4sGCkaZeKKwDkMt87rAtScZan",
    type: "google",
    image_link:
      "https://lh3.googleusercontent.com/a-/ALV-UjWOh24z1nEhGpLqqdbrHkGOMnV4sGCkaZeKKwDkMt87rAtScZan=w63-h63-p-rp-mo-ba4",
    customer_name: "Vanessa Puente",
    created_time: "2 years ago",
    link: "https://goo.gl/maps/Gixp8649japUZREf8",
    review_text:
      "Helpful people, place is small. Just right for small businesses.",
  },
  {
    review_id: "ALV-UjX0foAjUd8VLEi-nx7L1HSYTX3rnV287AC57iTQs2TJlXML7HSn",
    type: "google",
    image_link:
      "https://lh3.googleusercontent.com/a-/ALV-UjX0foAjUd8VLEi-nx7L1HSYTX3rnV287AC57iTQs2TJlXML7HSn=w63-h63-p-rp-mo-ba3",
    customer_name: "Karl Vincent Jude Baculina",
    created_time: "2 years ago",
    link: "https://goo.gl/maps/Gixp8649japUZREf8",
    review_text: "One of the lowest price retailers.",
  },
  {
    review_id: "ALV-UjU0ZvXGhrXqSxX6n9RCa1IKF8pWPat4ZqAOV1LPirHAj7b4OZeb",
    type: "google",
    image_link:
      "https://lh3.googleusercontent.com/a-/ALV-UjU0ZvXGhrXqSxX6n9RCa1IKF8pWPat4ZqAOV1LPirHAj7b4OZeb=w63-h63-p-rp-mo-ba3",
    customer_name: "The Saints",
    created_time: "2 years ago",
    link: "https://goo.gl/maps/Gixp8649japUZREf8",
    review_text: "Been here a while ago, their staff is kind.",
  },
  {
    review_id: "g8ocIwQ4eUNhxfAJh0AAmZfjEe5N06xRGqtC9cCDfdVF48aYkPXyE",
    type: "google",
    image_link:
      "https://lh3.googleusercontent.com/a/ACg8ocIwQ4eUNhxfAJh0AAmZfjEe5N06xRGqtC9cCDfdVF48aYkPXyE=w63-h63-p-rp-mo",
    customer_name: "Mark Steven Amador",
    created_time: "3 years ago",
    link: "https://goo.gl/maps/Gixp8649japUZREf8",
    review_text: "Very hospitable and kind service are provided",
  },
  {
    review_id: "ALV-UjW86uptQTc9x5EclPRB5xbI-JUXz9dBrxqzJNdJi_cpL-YAi470",
    type: "google",
    image_link:
      "https://lh3.googleusercontent.com/a-/ALV-UjW86uptQTc9x5EclPRB5xbI-JUXz9dBrxqzJNdJi_cpL-YAi470=w63-h63-p-rp-mo",
    customer_name: "Ramona Enriquez",
    created_time: "3 years ago",
    link: "https://goo.gl/maps/Gixp8649japUZREf8",
    review_text:
      "Best price ever. ❤ All of them are super friendly. Even their lovable pups. 😍 …",
  },
  {
    review_id: "ALV-UjW-6Xf5czKLeG_YLZYUkmelq9hE94158XJbhfB2-MBf_ipXTOEgtw",
    type: "google",
    image_link:
      "https://lh3.googleusercontent.com/a-/ALV-UjW-6Xf5czKLeG_YLZYUkmelq9hE94158XJbhfB2-MBf_ipXTOEgtw=w63-h63-p-rp-mo",
    customer_name: "Louise Margot Ellamil",
    created_time: "3 years ago",
    link: "https://goo.gl/maps/Gixp8649japUZREf8",
    review_text:
      "Accommodating people and the products they sell are affordable hehe thanks",
  },
  {
    review_id: "g8ocJ0NYf01CP8k359QP-xzNSJsOoR9d52AngHH9Bb2CrIdhlN9w",
    type: "google",
    image_link:
      "https://lh3.googleusercontent.com/a/ACg8ocJ0NYf01CP8k359QP-xzNSJsOoR9d52AngHH9Bb2CrIdhlN9w=w63-h63-p-rp-mo",
    customer_name: "joshua familaran",
    created_time: "3 years ago",
    link: "https://goo.gl/maps/Gixp8649japUZREf8",
    review_text: "Best price and best service ever",
  },
];
